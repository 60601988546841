/* Social Links */

.social-links {
  display: flex;
  gap: var(--xl-gutter);
  margin-top: var(--m-gutter);
}

.social-link-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.social-icon {
  height: var(--icon-size);
  width: var(--icon-size);
}

.social-name {
  font-size: var(--icon-font-size);
}

@media only screen and (min-width: 720px) {
  .social-links {
    bottom: var(--xl-gutter);
    position: fixed;
  }
}
