/* Navigation */

.navigation {
  margin: var(--xl-gutter) 0;
  display: none;
}

@media only screen and (min-width: 720px) {
  .navigation {
    display: block;
  }
}

.navigation-container {
  display: flex;
  flex-direction: column;
  gap: var(--m-gutter);
}

.navigation-link {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  transition: all 0.5s ease-out;
  will-change: transform;
}

.navigation-link:hover {
  color: var(--ternary);
  transform: scale(1.2);
  transform-origin: left;
}
