:root {
  /* Theme */
  --primary: #0f172a;
  --secondary: #8ecae6;
  --ternary: #219ebc;
  --headings: #80ed99;
  --links: #ffb703;
  --links-opacity: #ffb8031a;
  --light: #ffffff;
  --dark: #000000;
  /* Spacing */
  --xs-gutter: 2px;
  --s-gutter: 4px;
  --gutter: 8px;
  --m-gutter: 16px;
  --xl-gutter: 32px;
  --xxl-gutter: 64px;
  /* Settings */
  --app-max-width: 1024px;
  --header-height: 180px;
  --header-min-width: 375px;
  /* Font Sizes */
  --s-font-size: 12px;
  --font-size: 16px;
  /* Social Icons */
  --icon-size: 32px;
  --icon-font-size: 12px;
  /* Experience */
  --experience-title-height: 64px;
  /* Opacities */
  --opacity-weak: 0.85;
  --opacity-strong: 0.4;
}
