/* App Container */

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: var(--app-max-width);
}

@media only screen and (min-width: 720px) {
  .app-container {
    flex-direction: row;
    gap: var(--xl-gutter);
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--xl-gutter);
}
